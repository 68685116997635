<template>
  <div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="open">
        <div class="flix-note flix-bg-warning">
          <div class="flix-form-group">
            <textarea v-model="msg" class="flix-form-control"></textarea>
          </div>
            <a href="#" @click.prevent="saveNote()" class="flix-btn flix-btn-default flix-btn-xs">{{ $t("message.save", {name: $tc("message.note", 1)}) }}</a>
        </div>
      </div>
    </transition>
    <div class="flix-form-group flix-text-right">
      <addBtn :onClick="function () { open = !open }"><template v-slot:text>{{ $t('message.add', {name: $tc("message.note", 1)}) }}</template></addBtn>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { callback: Function },
  data () {
    return {
      open: false,
      msg: ''
    }
  },
  methods: {
    setOpen () {
      alert('test')
      this.open = !this.open
    },
    saveNote () {
      if (this.msg === '') {
        return false
      }
      var d = new Date()
      this.open = false

      this.callback({
        msg: this.$nl2br(this.msg),
        user: this.$store.getters.user.username,
        date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
      })
      this.msg = ''
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-note
    transition: all .2s ease-in
    box-shadow: 0px 24px 12px -18px rgba(0,0,0,0.5)
    color: black
    display: block
    min-height: 20px
    padding: 19px
    margin-bottom: 20px
    border-radius: 5px

    textarea
      background-color: rgba(255,255,255,0.3)
      color: black
      border: 0
</style>
